import axios from "axios";
import QS from "qs"; // QS处理请求参数的序列化
import { toast } from "@/composables/util";
// import { userStore } from "./store/user";
// const baseURL = '/api'
const baseURL = `${window.location.protocol}//${window.location.host}`
const service = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
});

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    if (response.data.code === 0) {
      toast(response.data.msg, "error");
      return Promise.reject(response);
    }else if(response.data.code === -1){
        localStorage.removeItem('userInfo')
        location.reload();
    } else {
      return response.data;
    }
  },
  function (error) {
    const msg = error.response.data.msg || "请求失败";
    toast(msg, "error");
    return Promise.reject(error);
  }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  service
    .get(url, {
      params: params,
    })
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      console.log("err:", err);
      return Promise.reject(err);
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return service
    .post(url, QS.stringify(params))
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

// 发送文件上传请求
export function upload(url, formData) {
  return service
    .post(url, formData, {
      headers: {
        // 表示上传的是文件,而不是普通的表单数据
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
