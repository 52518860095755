<template>
  <el-dialog
    v-model="dialogFormVisible"
    :show-close="false"
    :width="width"
    draggable
    :modal="false"
    destroy-on-close
    align-center
  >
    <div class="my-header">
      <div @click="setModalShow(false)">
        <el-icon class="el-icon--left" :size="24"
          ><CircleCloseFilled color="#B2B2B2"
        /></el-icon>
      </div>
    </div>
    <slot name="content"></slot>
  </el-dialog>
</template>
<script setup>
import { ref, defineExpose, defineProps } from "vue";
const dialogFormVisible = ref(false);
const setModalShow = (bool) => {
  dialogFormVisible.value = bool;
};
defineProps({
  width: {
    type: Number,
    default: 500,
  },
});
defineExpose({ setModalShow });
</script>
<style scoped>
.my-header {
  text-align: right;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
}
</style>
