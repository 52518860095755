<template>
  <el-dialog
    v-model="dialogFormVisible"
    :show-close="false"
    :width="modalConfig['dialogWidth'] || '400px'"
    draggable
    :modal="false"
    destroy-on-close
    align-center
  >
    <template #header="{ titleId, titleClass }">
      <div class="my-header" style="margin: 0 30px;">
        <h4 :id="titleId" :class="titleClass">{{ title }}</h4>
        <div
          style="cursor: pointer; padding: 20px 30px 20px 20px;"
          @click="closeModal(ruleFormRef)"
        >
          <el-icon class="el-icon--left" :size="24"
            ><CircleCloseFilled color="#B2B2B2"
          /></el-icon>
        </div>
      </div>
    </template>
    <div style="margin: 0 30px;">
      <el-form
        :rules="props.modalConfig.rules"
        :model="formData"
        ref="ruleFormRef"
        label-position="left"
        :label-width="modalConfig['labelWidth'] || '80px'"
      >
        <template v-for="item in modalConfig.formItems" :key="item.prop">
          <el-form-item :label="item.label" :prop="item.prop">
            <template v-if="item.type === 'date-picker'">
              <el-date-picker
                type="daterange"
                v-model="formData[item.prop]"
                range-separator="-"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              />
            </template>
            <template v-else-if="item.type === 'select'">
              <el-select
                :placeholder="item.placeholder"
                v-model="formData[item.prop]"
                style="width: 100%"
              >
                <template v-for="op in item.options" :key="op.value">
                  <el-option :label="op.label" :value="op.value"></el-option>
                </template>
              </el-select>
            </template>
            <template v-else-if="item.type === 'custom'">
              <slot :name="item.slotName"></slot>
            </template>
            <template v-else>
              <el-input
                :placeholder="item.placeholder"
                v-model="formData[item.prop]"
                :type="item.inputType || 'text'"
              ></el-input>
            </template>
          </el-form-item>
        </template>
      </el-form>
      <div class="operate-btn">
        <el-button type="primary" @click="clickSubmitFn(ruleFormRef)">{{
          btnText
        }}</el-button>
        <slot :name="modalConfig.extends" v-if="modalConfig.extends"></slot>
      </div>
    </div>
  </el-dialog>
</template>
<script setup>
import {
  reactive,
  ref,
  nextTick,
  defineProps,
  defineEmits,
  defineExpose,
} from "vue";

import { debounce } from "@/composables/util";
const props = defineProps({
  modalConfig: {
    type: Object,
    required: true,
    default: () => ({
      btnText: "",
      header: {
        newTitle: "",
        editTitle: "",
      },
      formItems: [],
      rules: [],
      extends: "",
    }),
  },
  otherInfo: {
    type: [Object, String, Number, Array], // 允许多种类型
    default: null,
  },
});
const emits = defineEmits(["submit"]);
const title = ref();
const btnText = ref();
const dialogFormVisible = ref(false);

const ruleFormRef = ref();
const initialForm = {};
// 初始化formData 因为modal一开始就是false的 不渲染初始值
for (const item of props.modalConfig.formItems) {
  initialForm[item.prop] = item.initialValue ?? "";
}
let formData = reactive(initialForm);

const setModalVisible = (isNew, itemData) => {
  dialogFormVisible.value = true;
  if (!isNew && itemData) {
    //编辑
    nextTick(() => {
      for (const key in formData) {
        formData[key] = itemData[key];
        title.value = props.modalConfig.header?.editTitle;
      }
    });
  } else {
    title.value = props.modalConfig.header?.newTitle;
  }
  btnText.value = props.modalConfig.btnText;
};
const handleConfirmClick = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid) => {
    if (valid) {
      let infoData = { ...formData };
      if (props.otherInfo) {
        infoData = { ...formData, ...props.otherInfo };
      }
      //   dialogFormVisible.value = false;
      emits("submit", infoData);
    }
  });
};
const closeModal = (formEl) => {
  if (!formEl) return;
  formEl.resetFields();
  dialogFormVisible.value = false;
};

const setModalHidden = () => {
  dialogFormVisible.value = false;
};
const hiddenPassword = () => {
  for (const item of props.modalConfig.formItems) {
    initialForm[item.prop] = item.initialValue ?? "";
  }
  formData = reactive(initialForm);
  dialogFormVisible.value = false;
};
const clickSubmitFn = debounce((ruleFormRef)=>{
  handleConfirmClick(ruleFormRef)
}, 500, true)
defineExpose({
  setModalVisible,
  closeModal,
  setModalHidden,
  hiddenPassword,
});
</script>
<style scoped>
.operate-btn {
  margin-top: 20px;
  margin-bottom: 40px;
  .el-button {
    width: 100%;
    font-size: 18px;
    height: 40px;
    border-radius: 6px;
    background-color: #4f70cb;
  }
}
:deep(.el-form-item__label) {
  font-size: 16px;
}
:deep(.el-dialog__title) {
  font-size: 24px;
  color: #000000;
}
:deep(.el-dialog.is-draggable .el-dialog__header) {
  margin-right: 0 !important;
}
:deep(.el-dialog__header) {
  margin-right: 0 !important;
}
header {
  margin-right: 0 !important;
}
.el-dialog__header {
  margin-right: 0 !important;
  padding: 0;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  height: 60px;
  margin-right: 0 !important;
}
.el-form-item {
  margin-bottom: 22px;
}
.pdl-11 {
  width: 11px;
}
</style>
<style>
.el-dialog__header {
  margin-right: 0 !important;
  padding: 0 0 20px 20px !important;
}
.el-form-item__error {
  font-size: 14px !important;
}
.el-dialog {
  border-radius: 20px !important;
}
@media screen and (min-width: 220px) and (max-width: 600px) {
  .el-dialog {
    width: 95% !important;
  }
}
.el-input__inner {
  font-size: 16px;
  height: 32px;
}
</style>
