<template>
  <div class="account ml-20" v-if="account">
    <el-dropdown>
      <div class="el-dropdown-link font16">
        {{ account }}{{ is_member ? "（VIP用户）" : "（普通用户）"
        }}<span class="blue mr5">修改</span>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="changePassword">修改密码</el-dropdown-item>
          <el-dropdown-item divided @click="changePhone"
            >修改手机号/邮箱</el-dropdown-item
          >
          <el-dropdown-item divided @click="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
  <div class="account ml-20 font-17" v-else>
    <span @click="login" class="blue">登录</span>
    <span @click="register" class="blue ml30">注册</span>
  </div>
  <div class="diif-page">
    <div class="header">
      <span style="font-size: 24px">基差分析(注意收藏)</span>
    </div>
    <div class="time" style="margin-right: 20px">
      <span>{{ time }}</span>
    </div>
    <div v-for="group of groupList" :key="group.Name" class="diff-group">
      <el-card>
        <template #header>
          <div>{{ group.diffGrop.Name }}</div>
        </template>
        <el-table
          :data="group.diffList"
          stripe
          style="width: 100%"
          :border="true"
        >
          <el-table-column prop="Name" label="产品" min-width="15%" />
          <el-table-column
            prop="LeftValue"
            :label="group.diffGrop.LeftLable"
            min-width="20%"
          >
            <template #default="scope">
              <div :class="getValueColor(scope.row, 'LeftOffset')">
                {{ formatLeft(scope.row) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="RightValue"
            :label="group.diffGrop.RightLable"
            min-width="20%"
          >
            <template #default="scope">
              <div :class="getValueColor(scope.row, 'RightOffset')">
                {{ formatRight(scope.row) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="DiffValue"
            :label="group.diffGrop.ValueLable"
            min-width="45%"
          >
            <template #default="scope">
              <div class="flex" :class="{ 'flex-cloumn': isMobile }">
                <div class="flex-half">{{ formatDfiff(scope.row) }}</div>
                <div
                  class="flex-half diff-text cus-poin"
                  @click="viewTrend(scope.row, 'DiffValue')"
                >
                  查看走势
                </div>

                <div
                  :class="{ 'flex-cloumn': isMobile }"
                  class="flex-2 flex"
                  v-if="
                    (scope.row.basis_rise && scope.row.basis_rise.length) ||
                    (scope.row.basis_fall && scope.row.basis_fall.length)
                  "
                >
                  <span
                    class="font-14"
                    v-if="scope.row.basis_rise && scope.row.basis_rise.length"
                    >上涨通知：{{ scope.row.basis_rise.join("，") }}<br
                  /></span>
                  <span
                    class="font-14"
                    :class="{ 'ml-20': !isMobile }"
                    v-if="scope.row.basis_fall && scope.row.basis_fall.length"
                    >下跌通知：{{ scope.row.basis_fall.join("，") }}</span
                  >
                  <div
                    class="diff-text cus-poin change-text"
                    :class="{ 'ml-20': !isMobile }"
                    @click="setNotice(scope.row)"
                  >
                    修改
                  </div>
                </div>
                <div
                  class="flex-2 diff-text cus-poin"
                  @click="setNotice(scope.row)"
                  v-else
                >
                  设置通知
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <img
          src="../../public/ad.jpg"
          class="ad"
          v-if="group.ID == 'g001'"
          @click="showAd"
        />
      </el-card>
    </div>
    <div>
      <el-dialog
        v-model="dialogVisible"
        :width="isMobile ? '100%' : '90%'"
        draggable
        align-center
      >
        <div :class="{ 'mar-100': !isMobile }">
          <div class="dialog-title">基差走势图</div>
          <div class="dialog-tabs">
            <span
              style="margin-right: 30px; cursor: pointer"
              v-for="(item, index) in tabsSelect"
              :key="index"
              :class="{ 'active-tab': defaultIndex === index }"
              @click="clickTabs(index)"
              >{{ item.name }}</span
            >
          </div>
          <div
            class="dialog-tool"
            v-if="defaultIndex === 2 || defaultIndex === 3"
          >
            <span class="dialog-subTitle">搜索时间段</span>
            <div v-if="isMobile && defaultIndex === 2">
              <div style="margin: 10px 0">
                <span style="margin-right: 10px">开始时间</span>
                <el-date-picker
                  v-model="value1Mobile"
                  type="datetime"
                  placeholder="请选择开始时间"
                  :editable="false"
                  :clearable="false"
                  unlink-panels
                  format="YYYY-MM-DD HH"
                  value-format="X"
                  time-format="HH时"
                  :disabled-date="disabled1MobileFn"
                />
              </div>
              <div style="margin-bottom: 10px">
                <span style="margin-right: 10px">结束时间</span>
                <el-date-picker
                  v-model="value2Mobile"
                  type="datetime"
                  placeholder="请选择结束时间"
                  :editable="false"
                  :clearable="false"
                  unlink-panels
                  format="YYYY-MM-DD HH"
                  value-format="X"
                  time-format="HH时"
                  :disabled-date="disabled2MobileFn"
                  :default-time="new Date(2000, 2, 1, 23, 0, 0)"
                />
              </div>
            </div>
            <div v-if="isMobile && defaultIndex === 3">
              <div style="margin: 10px 0">
                <span style="margin-right: 10px">开始时间</span>
                <el-date-picker
                  v-model="value3Mobile"
                  type="date"
                  placeholder="请选择开始时间"
                  :editable="false"
                  :clearable="false"
                  value-format="X"
                  :disabled-date="disabled3MobileFn"
                />
              </div>
              <div style="margin-bottom: 10px">
                <span style="margin-right: 10px">结束时间</span>
                <el-date-picker
                  v-model="value4Mobile"
                  type="date"
                  placeholder="请选择结束时间"
                  :editable="false"
                  :clearable="false"
                  value-format="X"
                  :disabled-date="disabled4MobileFn"
                />
              </div>
            </div>
            <div
              v-if="!isMobile"
              style="
                flex: 0 0 auto;
                width: auto;
                margin: 0 10px;
                max-width: 300px;
              "
            >
              <el-date-picker
                v-model="value1Pc"
                type="datetimerange"
                :editable="false"
                :clearable="false"
                unlink-panels
                format="YYYY-MM-DD HH"
                time-format="HH"
                :teleported="false"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                range-separator="→"
                v-if="defaultIndex === 2"
                :disabled-date="disabled1PcFn"
                @change="change1PcFn"
                :default-time="[
                  new Date(2000, 1, 1, 0, 0, 0),
                  new Date(2000, 2, 1, 23, 0, 0),
                ]"
              />
              <el-date-picker
                v-model="value2Pc"
                type="daterange"
                :editable="false"
                :clearable="false"
                unlink-panels
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                range-separator="→"
                v-if="defaultIndex === 3"
                :disabled-date="disabled2PcFn"
                @calendar-change="change2PcFn"
              />
            </div>
            <div>
              <el-button color="#664f4f" @click="clearTimeFn">清空</el-button>
              <el-button type="primary" @click="searchFn">搜索</el-button>
              <el-button type="info" @click="refreshFn"
                >刷新</el-button
              >
            </div>
          </div>
          <div
            class="dialog-tool"
            v-if="defaultIndex === 0 || defaultIndex === 1"
          >
            <el-button type="info" @click="refreshFn"
              >刷新</el-button
            >
          </div>
          <div v-loading="loading"><span id="chart"></span></div>
        </div>
      </el-dialog>

      <PageModal
        ref="changePhoneRef"
        :modalConfig="changePhoneConfigRef"
        @submit="submitChangePhone"
      ></PageModal>
      <PageModal
        ref="loginRef"
        :modalConfig="loginConfigRef"
        @submit="submitLogin"
      >
        <template #custom-btn>
          <div class="mt30" @click="registerAccountFn">注册账号</div>
        </template>
      </PageModal>
      <PageModal
        ref="registerRef"
        :modalConfig="registerConfigRef"
        @submit="submitRegister"
      >
        <template #custom-btn>
          <div class="mt30" @click="loginAccountFn">已有账号去登录</div>
        </template>
      </PageModal>
      <PageModal
        ref="changePasswordRef"
        :modalConfig="changeConfigRef"
        @submit="submitChangePassword"
      ></PageModal>
      <ServiceDialog ref="serviceRef">
        <template #content>
          <div class="content">
            <div class="sub-title">{{ title }}</div>
            <div class="sub-title">{{ sub_title }}</div>
            <el-image
              style="width: 300px; height: 300px; margin-top: 15px"
              :src="service"
              :preview-src-list="[service]"
            />
          </div>
        </template>
      </ServiceDialog>
      <ServiceDialog ref="basicRef" :width="700">
        <template #content>
          <div style="padding: 0 40px 40px 40px">
            <div class="basic-title">基差通知</div>
            <div class="basic-subtitle">
              设置基差价格通知后，系统会自动发送通知至：{{ notification
              }}<span class="operate" @click="basicChangePhone">修改</span>
            </div>
            <div class="basic-subtitle email-notification">
              如使用邮箱，建议使用QQ邮箱绑定微信通知<span
                class="operate"
                @click="viewTutorial"
                >点击查看教程</span
              >
            </div>
            <div class="basic-input">
              <span class="input-label">涨幅通知：</span
              ><el-input
                :placeholder="basicPlaceholder"
                v-model="basic.increase"
              ></el-input>
            </div>
            <div class="basic-input">
              <span class="input-label">降幅通知：</span
              ><el-input
                :placeholder="basicPlaceholder"
                v-model="basic.decrease"
              ></el-input>
            </div>
            <div class="btns">
              <el-button class="save-btn" @click="saveBasicDialog"
                >保存</el-button
              >
              <el-button class="cancel-btn" @click="cancelBasicDialog"
                >取消</el-button
              >
            </div>
          </div>
        </template>
      </ServiceDialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
import PageModal from "./cusDialog.vue";
import ServiceDialog from "./serviceDialog.vue";
import passwordData from "./hooks/changePassword.js";
import loginData from "./hooks/loginPassword.js";
import registerData from "./hooks/registerFn.js";
import changePhoneData from "./hooks/changePhone.js";
import {
  isTimeDisabled,
  formatTimestamp,
  formatDaystamp,
  filterDataByTimeSpan,
} from "./hooks/checkTime.js";
import {
  getUserInfoAPI,
  loginAPI,
  registerAPI,
  setUserPasswordAPI,
  setUserInfoAPI,
  logoutAPI,
  getWxCodeAPI,
  setUserBasisAPI,
  getUserBasisAPI,
  getCurrentMinBasisLineAPI,
  getHourDateBasisLineAPI,
  getUserAllBasisAPI,
  getUserInformMessageAPI,
} from "@/api/login.js";
import { toast, debounce } from "@/composables/util";
import diffData from "@/data/diff.js";
import { Decimal } from "decimal.js";
function dateFormat(fmt, date) {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (const k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}

export default {
  components: {
    PageModal,
    ServiceDialog,
  },
  name: "DiffPage",
  props: {
    msg: String,
  },
  created() {
    // this.getLatestInfo();
    this.loadCache();
    this.ws();
  },
  mounted() {
    console.log(process.env.NODE_ENV);
    this.updateMedia();
    // 更新时间
    setInterval(() => {
      this.time = dateFormat("YYYY-mm-dd HH:MM:SS", new Date());
    }, 1000);
    // 防抖方法
    this.debounceShowChart = debounce(
      () => {
        this.windowActualWidth = window.innerWidth * 0.9 - 32 - 200;
        this.updateMedia();
        this.showChart();
      },
      300,
      false
    ); // 延迟 300ms

    window.onresize = () => {
      this.debounceShowChart(); // 调用防抖函数
    };
  },

  data() {
    return {
      dialogVisible: false,
      groupList: [],
      clickRow: {},
      time: "--",
      is_old: false,
      operateDialog: false,
      changePhoneRef: {},
      loginRef: {},
      registerRef: {},
      changePasswordRef: {},
      serviceRef: {},
      basicRef: {},
      basic: {
        increase: "",
        decrease: "",
      },
      basicPlaceholder: "请输入需要通知的价格推送（可含小数点），如：-6，9，20",
      title: "",
      sub_title: "",
      service: "",
      ask_code: "",
      bid_code: "",
      isMobile: false,
      tabsSelect: [
        {
          name: "实时折线图",
          index: 0,
        },
        {
          name: "分K",
          index: 1,
        },
        {
          name: "时K",
          index: 2,
        },
        {
          name: "日K",
          index: 3,
        },
      ],
      defaultIndex: 0, //初始为实时折线图
      startTime1Pc: "",
      endTime1Pc: "",
      value1Pc: [],
      value2Pc: [],
      startTime2Pc: "",
      endTime2Pc: "",
      value1Mobile: "",
      value2Mobile: "",
      value3Mobile: "",
      value4Mobile: "",
      loading: false,
      windowActualWidth: 0,
      notification: "",
    };
  },
  computed: {
    changeConfigRef() {
      return passwordData;
    },
    loginConfigRef() {
      return loginData;
    },
    registerConfigRef() {
      return registerData;
    },
    changePhoneConfigRef() {
      return changePhoneData;
    },
    account() {
      return this.$store.state.userInfo.account || "";
    },
    phone() {
      return this.$store.state.userInfo.phone || "";
    },
    email() {
      return this.$store.state.userInfo.email || "";
    },
    is_member() {
      if (this.$store.state.userInfo.is_member) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async getLatestInfo() {
      const res = await getUserInfoAPI();
      if (!res.data.is_login) {
        this.$store.commit("removeUserInfo");
      } else {
        const existingUserInfo = this.$store.state.userInfo || {};
        const updatedUserInfo = {
          ...existingUserInfo,
          ...res.data,
        };
        this.$store.commit("updateUserInfo", updatedUserInfo);
      }
    },
    showAd() {
      document.open(
        "https://zx30321224.jzfkw.net/?checkWxLogin=true&openId=TEC9dEd6WHfOkJnWLC%2FVJzbcAQRjta0OCFpki5utK%2Fg%3D&secondAuth=true&appId=wx50775cad5d08d7ad",
        "",
        "noopener=true"
      );
    },
    getValueColor(row, vk) {
      const v = row[vk];
      if (v && v > 0) {
        return "value-color-down";
      }
      return "value-color-up";
    },

    getWsAddr() {
      return "ws://47.115.87.76:8885/ws";
      // if (process.env.NODE_ENV === "development") {
      //   return "ws://127.0.0.1:8885/ws";
      // }
      // const isHttps = window.location.protocol === "https:";
      // const wsProtocol = isHttps ? "wss" : "ws";
      // const host = window.location.host;
      // const wsaddr = `${wsProtocol}://${host}/api/ws`;
      // // const wsaddr = "ws://127.0.0.1:8083";
      // return wsaddr;
    },
    getApiAddr() {
      return "http://47.115.87.76:8885";
      // if (process.env.NODE_ENV === "development") {
      //   return "http://127.0.0.1:8885";
      // }
      // return "/api";
    },
    onShowD() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.showChart();
      });
    },
    async showChart() {
      if (document.getElementById("chart") == null) {
        return;
      }
      echarts.dispose(document.getElementById("chart"));
      this.windowActualWidth = window.innerWidth * 0.9 - 32 - 200;
      let w = window.screen.width * 0.9;
      if (w < 500) {
        this.heightChart = 450;
        this.windowActualWidth = window.innerWidth * 0.9;
      }
      const config = {
        width: this.windowActualWidth,
        height: this.heightChart,
      };
      // 基于准备好的dom，初始化echarts实例
      const doc = document.getElementById("chart");
      const myChart = echarts.init(doc, null, config);
      this.loading = true;
      const ask_code = this.ask_code;
      const bid_code = this.bid_code;
      let rsp = {};
      let startProgress = 0;
      let endPropgress = 100;
      // 实时折线图
      if (this.defaultIndex === 0) {
        rsp = await getCurrentMinBasisLineAPI({
          ask_code,
          bid_code,
          type: 0,
        });
        const twoHoursInSeconds = 2 * 60 * 60; // 2小时
        const indices2Hours = filterDataByTimeSpan(rsp.data, twoHoursInSeconds);
        if (indices2Hours) {
          startProgress =
            100 - (indices2Hours.actualStartIndex / rsp.data.length) * 100;
        } else {
          startProgress = 0;
        }
      }
      // 分K
      if (this.defaultIndex === 1) {
        rsp = await getCurrentMinBasisLineAPI({
          ask_code,
          bid_code,
          type: 1,
        });
        const twoHoursInSeconds = 2 * 60 * 60; // 2小时
        const indices2Hours = filterDataByTimeSpan(rsp.data, twoHoursInSeconds);
        if (indices2Hours) {
          startProgress =
            100 - (indices2Hours.actualStartIndex / rsp.data.length) * 100;
        } else {
          startProgress = 0;
        }
      }
      // 时K 还需要注意 如果执行了时间跨度筛选，则默认展示全部的数据
      if (this.defaultIndex === 2) {
        const { start, end } = this.getFormattedTime(
          this.startTime1Pc,
          this.endTime1Pc,
          this.value1Mobile,
          this.value2Mobile
        );
        rsp = await getHourDateBasisLineAPI({
          ask_code,
          bid_code,
          startTime: start,
          endTime: end,
          type: 1,
        });
        const twoHoursInSeconds = 24 * 60 * 60; // 24小时
        const indices2Hours = filterDataByTimeSpan(rsp.data, twoHoursInSeconds);
        if (start && end) {
          startProgress = 0;
        } else if (indices2Hours) {
          startProgress =
            100 - (indices2Hours.actualStartIndex / rsp.data.length) * 100;
        } else {
          startProgress = 0;
        }
      }
      // 日K
      if (this.defaultIndex === 3) {
        const { start, end } = this.getFormattedDay(
          this.startTime2Pc,
          this.endTime2Pc,
          this.value3Mobile,
          this.value4Mobile
        );
        rsp = await getHourDateBasisLineAPI({
          ask_code,
          bid_code,
          startTime: start,
          endTime: end,
          type: 2,
        });
        const twoHoursInSeconds = 90 * 24 * 60 * 60; // 90天
        const indices2Hours = filterDataByTimeSpan(rsp.data, twoHoursInSeconds);
        if (start && end) {
          startProgress = 0;
        } else if (indices2Hours) {
          startProgress =
            100 - (indices2Hours.actualStartIndex / rsp.data.length) * 100;
        } else {
          startProgress = 0;
        }
      }
      const hisList = rsp.data;
      const yd = [];
      const xd = [];
      const ydl = [];

      this.loading = false;

      if (!hisList || hisList.length === 0) {
        const noDataMessage = document.getElementById("chart");
        noDataMessage.innerHTML =
          "<div style='text-align: center; font-size: 16px; color: gray;'>暂无数据</div>";
        noDataMessage.style.width = `${config.width}px`;
        noDataMessage.style.height = `${config.height}px`;
        noDataMessage.style.display = "flex";
        noDataMessage.style.justifyContent = "center";
        noDataMessage.style.alignItems = "center";
        return;
      }
      for (const h of hisList) {
        xd.push(h.time);
        yd.push(h.gold_diff);
        ydl.push([h.start_diff, h.end_diff, h.max_diff, h.min_diff]);
      }

      let series = [
        {
          data: yd.reverse(),
          type: "line",
        },
      ];

      if (this.defaultIndex !== 0) {
        series = [
          {
            type: "candlestick",
            data: ydl.reverse(),
          },
        ];
      }

      let option = {
        tooltip: {
          trigger: "axis", // 触发方式为轴触发
          formatter: (params) => {
            const point = params[0];
            if (this.defaultIndex === 0) {
              return `<strong>${point.axisValue}</strong><br/>
              <table style="width: 100%; border-collapse: collapse;">
                <tr>
                  <td style="text-align: left; padding-right: 10px;">基差</td>
                  <td style="text-align: right;">${point.data}</td>
                </tr>
              </table>`;
            } else {
              return `
                  <strong>${point.axisValue}</strong><br/>
                  <table style="width: 100%; border-collapse: collapse;">
                    <tr>
                      <td style="text-align: left; padding-right: 10px;">开盘</td>
                      <td style="text-align: right;">${point.data[1]}</td>
                    </tr>
                    <tr>
                      <td style="text-align: left; padding-right: 10px;">最高</td>
                      <td style="text-align: right;">${point.data[3]}</td>
                    </tr>
                    <tr>
                      <td style="text-align: left; padding-right: 10px;">最低</td>
                      <td style="text-align: right;">${point.data[4]}</td>
                    </tr>
                    <tr>
                      <td style="text-align: left; padding-right: 10px;">收盘</td>
                      <td style="text-align: right;">${point.data[2]}</td>
                    </tr>
                    <tr>
                      <td style="text-align: left; padding-right: 10px;">涨跌</td>
                      <td style="text-align: right;">${new Decimal(
                        Number(point.data[2])
                      ).minus(new Decimal(Number(point.data[1])))}</td>
                    </tr>
                  </table>
              `;
            }
          },
          axisPointer: {
            type: "line", // 指示线
          },
        },
        xAxis: {
          type: "category",
          data: xd.reverse(),
        },
        yAxis: {
          type: "value",
          scale: true,
        },
        series,
        // 添加 dataZoom 组件
        dataZoom: [
          {
            type: "inside", // 内部缩放（支持滚轮和触控缩放）
            xAxisIndex: 0, // 针对 x 轴
            start: startProgress,
            end: endPropgress,
          },
        ],
        grid: {
          x: 30,
          x2: 30,
        },
      };
      // 绘制图表
      myChart.setOption(option);
      myChart.resize(config);
    },
    getFormattedTime(startTime, endTime, value1, value2) {
      let start = "";
      let end = "";
      if (this.isMobile) {
        if (value1) start = formatTimestamp(value1, "type");
        if (value2) end = formatTimestamp(value2, "type");
      } else {
        if (startTime) start = formatTimestamp(startTime, "type");
        if (endTime) end = formatTimestamp(endTime, "type");
      }
      return { start, end };
    },
    getFormattedDay(startTime, endTime, value1, value2) {
      let start = "";
      let end = "";
      if (this.isMobile) {
        if (value1) start = formatTimestamp(value1);
        if (value2) end = formatDaystamp(value2);
      } else {
        if (startTime) start = formatTimestamp(startTime);
        if (endTime) end = formatDaystamp(endTime);
      }
      return { start, end };
    },

    loadCache(from) {
      axios.get(this.getApiAddr() + "/state-diff").then((res) => {
        // alert(JSON.stringify(res.data));
        this.setupTable(res.data.data, from);
      });
    },

    formatDfiff(row) {
      if (row.Decimal != undefined) {
        return row.DiffValue.toFixed(row.Decimal);
      }
      return row.DiffValue.toFixed(3);
    },

    formatLeft(row) {
      if (row.Decimal != undefined) {
        return row.LeftValue.toFixed(row.Decimal);
      }
      return row.LeftValue.toFixed(3);
    },

    formatRight(row) {
      if (row.Decimal != undefined) {
        return row.RightValue.toFixed(row.Decimal);
      }
      return row.RightValue.toFixed(3);
    },

    ws() {
      const conn = new WebSocket(this.getWsAddr());
      conn.onmessage = this.onmessage;

      conn.onclose = () => {
        setTimeout(() => this.ws(), 1000 * 10);
      };
    },
    onmessage(msg) {
      const data = JSON.parse(msg.data);
      for (const group of this.groupList) {
        if (data.Group == group.diffGrop.Name) {
          for (const diff of group.diffList) {
            if (diff.Name == data.Name) {
              try {
                diff.LeftOffset = data.LeftValue - diff.LeftOffset;
                diff.RightOffset = data.RightValue - diff.RightValue;
              } catch (e) {
                console.error(e);
              }
              diff.LeftValue = data.LeftValue;
              diff.RightValue = data.RightValue;
              diff.DiffValue = data.DiffValue;
            }
          }
        }
      }
    },
    async setupTable(data, from) {
      if (!from) {
        // 避免频繁请求
        const res = await getUserInfoAPI();
        if (!res.data.is_login) {
          this.$store.commit("removeUserInfo");
        } else {
          const existingUserInfo = this.$store.state.userInfo || {};
          const updatedUserInfo = {
            ...existingUserInfo,
            ...res.data,
          };
          this.$store.commit("updateUserInfo", updatedUserInfo);
        }
      }
      let basisTotal = [];
      if (this.account && this.is_member) {
        basisTotal = await getUserAllBasisAPI();
      }
      const groupList = [];
      for (const key in data) {
        const group = {};
        group.diffGrop = data[key].Group;
        group.diffList = data[key].DiffList;
        group.diffList = group.diffList.sort((a, b) => {
          if (a.ID < b.ID) {
            return -1;
          }
          return 1;
        });

        group.ID = data[key].Group.ID;
        groupList.push(group);
      }
      this.groupList = groupList.sort((a, b) => {
        if (a.ID < b.ID) {
          return -1;
        }
        return 1;
      });

      this.groupList.forEach((element, index1) => {
        element["diffList"].forEach((el2, index2) => {
          el2["ask_code"] = diffData[index1].DiffConfList[index2].Left.SrcName;
          el2["bid_code"] = diffData[index1].DiffConfList[index2].Right.SrcName;
          for (let i = 0; i < basisTotal.length; i++) {
            if (
              basisTotal[i]["ask_code"] == el2["ask_code"] &&
              basisTotal[i]["bid_code"] == el2["bid_code"]
            ) {
              el2["basis_rise"] = basisTotal[i]["basis_rise"];
              el2["basis_fall"] = basisTotal[i]["basis_fall"];
              break;
            }
          }
        });
      });
    },
    // 修改密码
    changePassword() {
      this.$refs.changePasswordRef.setModalVisible();
    },
    // 修改手机号/邮箱
    changePhone() {
      this.$refs.changePhoneRef.setModalVisible();
    },

    // 登录
    login() {
      this.$refs.loginRef.setModalVisible();
    },
    // 注册
    register() {
      this.$refs.registerRef.setModalVisible();
    },

    // 提交登录
    async submitLogin(data) {
      const result = await loginAPI(data);
      if (result.code === 1 && result.msg) {
        this.$store.commit("updateUserInfo", result.msg);
        toast("登录成功");
      }
      this.$refs.loginRef.setModalHidden();
    },
    // 提交注册
    async submitRegister(data) {
      const result = await registerAPI(data);
      if (result.code === 1) {
        this.$store.commit("updateUserInfo", data);
        toast("注册并登录成功");
      }
      this.$refs.registerRef.setModalHidden();
    },
    // 修改密码
    async submitChangePassword(data) {
      const params = { oldPassword: data.oldPassword, password: data.password };
      const result = await setUserPasswordAPI(params);
      if (result.code === 1) {
        toast("修改密码成功");
      }
      this.$refs.changePasswordRef.hiddenPassword();
    },
    // 修改手机号/邮箱
    async submitChangePhone(data) {
      const result = await setUserInfoAPI(data);
      if (result.code === 1) {
        const existingUserInfo = this.$store.state.userInfo || {};
        const updatedUserInfo = {
          ...existingUserInfo,
          ...data,
        };
        this.$store.commit("updateUserInfo", updatedUserInfo);
        toast("修改成功");
      }
      this.$refs.changePhoneRef.setModalHidden();
    },
    // 退出登录
    async logout() {
      // 清除本地的token
      await logoutAPI();
      toast("退出登录成功");
      this.$store.commit("removeUserInfo");
    },
    // 弹窗中的注册账号
    registerAccountFn() {
      // 先关闭登录账号的弹窗
      this.$refs.loginRef.setModalHidden();
      // 然后打开注册账号的弹窗
      setTimeout(() => {
        this.$refs.registerRef.setModalVisible();
      }, 200);
    },
    // 弹窗中的已有账号去登录
    loginAccountFn() {
      // 先关闭注册账号的弹窗
      this.$refs.registerRef.setModalHidden();
      // 然后再打开登录账号的弹窗
      setTimeout(() => {
        this.$refs.loginRef.setModalVisible();
      }, 200);
    },
    //联系的弹窗
    contact() {
      this.$refs.serviceRef.setModalShow(true);
    },
    // 基差通知的弹窗
    async basicFn() {
      const res = await getUserInformMessageAPI();
      this.notification = res.data;
      this.$refs.basicRef.setModalShow(true);
    },
    // 基差通知修改手机号码
    basicChangePhone() {
      this.$refs.basicRef.setModalShow(false);
      this.changePhone();
    },
    // 关闭基差通知的弹窗
    cancelBasicDialog() {
      this.$refs.basicRef.setModalShow(false);
    },
    // 保存基差
    async saveBasicDialog() {
      // 限制 空格 中英文逗号
      // if (!this.basic.decrease && !this.basic.increase) {
      //   toast("您未设置任何基差价格通知！", "error");
      //   return;
      // }

      // 正则表达式用于匹配合法的分隔符（中英文逗号和空格）
      const separatorRegex = /[,，\s]+/;
      const validateAndConvertInput = (input) => {
        if (!input) return []; // 如果输入为空，返回空数组
        return input
          .split(separatorRegex) // 按分隔符分割
          .filter((val) => val.trim() !== "") // 去掉空字符串
          .map((val) => {
            const num = Number(val.trim()); // 转换为数字
            if (isNaN(num)) {
              toast(`无效的数值：${val}`, "error"); // 如果转换失败，抛出错误
            }
            return num;
          });
      };

      const decreaseValues = validateAndConvertInput(this.basic.decrease);
      const increaseValues = validateAndConvertInput(this.basic.increase);
      if (increaseValues.length > 5) {
        toast("基差价格通知（涨幅）最多只能设置5个数值！", "error");
        return;
      }
      if (decreaseValues.length > 5) {
        toast("基差价格通知（降幅）最多只能设置5个数值！", "error");
        return;
      }
      const result = await setUserBasisAPI({
        ask_code: this.ask_code,
        bid_code: this.bid_code,
        basis_rise: JSON.stringify(increaseValues),
        basis_fall: JSON.stringify(decreaseValues),
      });
      if (result.code === 1) {
        toast("基差价格通知设置成功！");
        this.loadCache("setting");
        this.cancelBasicDialog();
      }
    },
    // 设置通知
    async setNotice(data) {
      // 用户没有登录时
      if (!this.account) {
        this.login();
        return;
      }
      if (!this.is_member) {
        // 弹出提示会员
        this.getService();
        this.contact();
        return;
      }
      this.basic.increase = "";
      this.basic.decrease = "";
      const [a, b] = this.extractNumbers(data.ID);
      this.ask_code = diffData[a - 1].DiffConfList[b - 1].Left.SrcName;
      this.bid_code = diffData[a - 1].DiffConfList[b - 1].Right.SrcName;
      const res = await getUserBasisAPI({
        ask_code: this.ask_code,
        bid_code: this.bid_code,
      });
      this.basic.increase = res.basis_rise?.length
        ? res.basis_rise.join("，")
        : "";
      this.basic.decrease = res.basis_fall?.length
        ? res.basis_fall.join("，")
        : "";
      this.basicFn();
    },
    extractNumbers(input) {
      // 使用正则表达式提取数字部分
      const match = input.match(/g(\d+)-(\d+)/);
      if (match) {
        const firstNumber = parseInt(match[1], 10); // 提取并转换第一个数字
        const secondNumber = parseInt(match[2], 10); // 提取并转换第二个数字
        return [firstNumber, secondNumber];
      } else {
        throw new Error(`无效的输入格式：${input}`);
      }
    },
    // 获取客服二维码
    async getService() {
      const res = await getWxCodeAPI();
      this.service = res.data.service;
      this.title = res.data.title;
      this.sub_title = res.data.sub_title;
    },
    // 判断页面宽度
    updateMedia() {
      if (window.visualViewport.width < 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    // 查看走势
    viewTrend(row, property) {
      const [a, b] = this.extractNumbers(row.ID);
      this.ask_code = diffData[a - 1].DiffConfList[b - 1].Left.SrcName;
      this.bid_code = diffData[a - 1].DiffConfList[b - 1].Right.SrcName;
      this.clickRow = row;
      if (property == "DiffValue") {
        this.onShowD();
      }
    },
    // 点击上面的tabs进行切换
    clickTabs(index) {
      // 用户没有登录时
      if (!this.account && (index === 2 || index == 3)) {
        this.login();
        return;
      }
      // 时K和日K需要会员才能使用
      if (!this.is_member && (index === 2 || index == 3)) {
        this.getService();
        // 弹出提示会员
        this.contact();
        return;
      }
      this.defaultIndex = index;
      this.showChart();
    },
    // 记录一下选择的时间
    changePcFn(time, type) {
      const convertToUnixTimestamp = (date) =>
        date ? Math.floor(date.getTime() / 1000) : "";
      this[`startTime${type}Pc`] = convertToUnixTimestamp(time[0]);
      this[`endTime${type}Pc`] = convertToUnixTimestamp(time[1]);
    },
    change1PcFn(time) {
      this.changePcFn(time, 1);
    },
    change2PcFn(time) {
      this.changePcFn(time, 2);
    },
    disabled1PcFn(time) {
      return isTimeDisabled(time, this.startTime1Pc);
    },
    disabled2PcFn(time) {
      return isTimeDisabled(time, this.startTime2Pc);
    },
    disabled1MobileFn(time) {
      return isTimeDisabled(time, this.value2Mobile);
    },
    disabled2MobileFn(time) {
      return isTimeDisabled(time, this.value1Mobile);
    },
    disabled3MobileFn(time) {
      return isTimeDisabled(time, this.value4Mobile);
    },
    disabled4MobileFn(time) {
      return isTimeDisabled(time, this.value3Mobile);
    },
    // 清空时间操作
    clearTimeFn() {
      if (this.defaultIndex === 2 && !this.isMobile) {
        this.value1Pc = [];
        this.startTime1Pc = "";
        this.endTime1Pc = "";
      }
      if (this.defaultIndex === 3 && !this.isMobile) {
        this.value2Pc = [];
        this.startTime2Pc = "";
        this.endTime2Pc = "";
      }
      if (this.defaultIndex === 2 && this.isMobile) {
        this.value1Mobile = "";
        this.value2Mobile = "";
      }
      if (this.defaultIndex === 3 && this.isMobile) {
        this.value3Mobile = "";
        this.value4Mobile = "";
      }
      this.showChart();
    },
    // 搜索
    searchFn() {
      // 通用检查时间范围函数
      const checkTimeRange = (startTime, endTime) => {
        if (!startTime && !endTime) {
          toast("请筛选时间后搜索", "error");
          return false;
        }
        if (!startTime) {
          toast("请选择开始时间", "error");
          return false;
        }
        if (!endTime) {
          toast("请选择结束时间", "error");
          return false;
        }
        if (endTime < startTime) {
          toast("开始时间不能大于结束时间", "error");
          return false;
        }
        // if (endTime - startTime > maxRangeInSeconds) {
        //   toast("选择时间跨度不能超过半年", "error");
        //   return false;
        // }
        return true;
      };
      const maxRangeInSeconds = 30 * 24 * 60 * 60 * 6; // 半年的秒数
      if (this.defaultIndex === 2 && this.isMobile) {
        if (
          !checkTimeRange(
            this.value1Mobile,
            this.value2Mobile,
            maxRangeInSeconds
          )
        )
          return;
      }
      if (this.defaultIndex === 3 && this.isMobile) {
        if (
          !checkTimeRange(
            this.value3Mobile,
            this.value4Mobile,
            maxRangeInSeconds
          )
        )
          return;
      }
      if (this.defaultIndex === 2 && !this.isMobile) {
        if (
          !checkTimeRange(this.startTime1Pc, this.endTime1Pc, maxRangeInSeconds)
        )
          return;
      }
      if (this.defaultIndex === 3 && !this.isMobile) {
        if (
          !checkTimeRange(this.startTime2Pc, this.endTime2Pc, maxRangeInSeconds)
        )
          return;
      }
      this.showChart();
    },
    // 刷新
    refreshFn() {
      this.showChart();
    },
    // 点击查看教程
    viewTutorial() {
      window.open(
        "https://jingyan.baidu.com/article/64d05a023fd2469f55f73bab.html"
      );
    },
  },
  watch: {
    dialogVisible(newVal) {
      if (!newVal) {
        this.defaultIndex = 0;
        this.value1Pc = [];
        this.startTime1Pc = "";
        this.endTime1Pc = "";
        this.value2Pc = [];
        this.startTime2Pc = "";
        this.endTime2Pc = "";
        this.value1Mobile = "";
        this.value2Mobile = "";
        this.value3Mobile = "";
        this.value4Mobile = "";
      }
    },
    account() {
      this.loadCache("account");
    },
  },
};
</script>
<style scoped lang="less">
.value-color-up {
  color: red;
}
.value-color-down {
  color: green;
}
::v-deep th {
  font-weight: 400;
  font-size: 18px;
}
::v-deep .el-table {
  font-size: 18px;
}
::v-deep .el-card__header {
  padding: 10px !important;
  text-align: center;
}

::v-deep .el-card__body {
  padding: 0px;
}

.header {
  margin-bottom: 10px;
}

.diff-group {
  margin-bottom: 35px;
}

.diif-page {
  width: 100%;
  // max-width: 1350px;
}

.diif-title {
  margin-bottom: 20px;
}

#chart {
  width: 1920px;
  height: 650px;
  /* position: relative; */
}

.time {
  text-align: right;
  /* padding-right: 3px; */
  padding-bottom: 10px;
  /* margin-bottom: 20px; */
}
.ad {
  width: 100%;
  max-width: 450px;
  height: 100px;
}
.account {
  text-align: left;
  margin-bottom: 10px;
  cursor: pointer;
}
.mt30 {
  margin-top: 20px;
  cursor: pointer;
  color: #000000;
  font-size: 18px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sub-title {
  color: #000000;
  font-size: 18px;
  margin-bottom: 4px;
}
.basic-title {
  font-size: 24px;
  font-weight: 700;
  color: #000000;
}
.basic-subtitle {
  text-align: left;
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 16px;
  .operate {
    margin-left: 17px;
    color: #516bc0;
    cursor: pointer;
  }
}
.email-notification {
  margin-top: 0;
  margin-bottom: 20px;
}
.basic-input {
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 12px;
  // color: #000000;
  .input-label {
    width: 120px;
    font-size: 16px;
  }
}
.btns {
  display: flex;
  width: 100%;
  margin-top: 20px;
  .save-btn {
    background-color: #4f70cb;
    border-radius: 6px;
    color: #fff;
    flex: 1;
  }
  .cancel-btn {
    background-color: #fff;
    border: 1px solid #000000;
    border-radius: 6px;
    color: #000000;
    flex: 1;
  }
}
.diff-text {
  font-size: 15px;
  color: #516bc0;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex {
  display: flex;
  align-items: center;
}
.change-text {
  white-space: nowrap;
}
.blue {
  color: #516bc0;
}
.font-14 {
  font-size: 14px;
}
.mr5 {
  margin: 0 5px;
}
.ml30 {
  margin-left: 30px;
}
.font16 {
  font-size: 16px;
}
.dialog-title {
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  color: #000000;
}
.dialog-subTitle {
  font-size: 16px;
  color: #000000;
}
.dialog-tool {
  display: flex;
  align-items: center;
  margin: 20px 0;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
}
.cus-poin {
  cursor: pointer;
}
.flex-cloumn {
  flex-direction: column;
}
:deep(.el-date-editor.el-input__wrapper) {
  width: auto;
}
.dialog-tabs {
  text-align: left;
  font-size: 16px;
  margin-top: 20px;
}
.active-tab {
  display: inline-block;
  border-bottom: 4px solid #4f70cb;
  padding-bottom: 3px;
}
.el-scrollbar:nth-child(2),
.el-scrollbar:nth-child(3) {
  display: none;
}
.ml-20 {
  margin-left: 20px;
}
.font-17 {
  font-size: 17px;
}
.mar-100 {
  margin: 0 100px;
}
.ml-20 {
  margin-left: 20px;
}
.flex-half {
  flex: 0.7;
}
:deep(.el-table .cell){
  @media(max-width: 768px){
    padding: 0;
  }
}
</style>
<style>
@media (max-width: 768px) {
  .el-date-range-picker .el-picker-panel__body {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    min-width: 50% !important;
  }
  .el-date-range-picker__time-header {
    display: flex !important;
    flex-direction: column;
    max-width: 100%;
    min-width: 50% !important;
  }
  .el-date-range-picker__content {
    max-width: 100% !important;
    width: 100% !important;
  }

  .el-date-range-picker {
    max-width: 100% !important;
    width: 100% !important;
  }
  .el-date-range-picker__content {
    margin: 0px;
    padding: 5px;
  }
  .el-date-range-picker__content.is-left {
    padding-bottom: 0px;
  }
  .el-date-range-picker__content.is-right {
    padding-top: 0px;
  }
  .el-date-table th {
    padding: 0px;
  }
  .el-date-table td {
    padding: 0px;
  }
}
.el-scrollbar:nth-child(2) {
  display: none;
}
.el-scrollbar:nth-child(3) {
  display: none;
}
.el-scrollbar:nth-child(1) {
  width: 100% !important;
}
.el-table .cell {
  margin-left: 10px;
}
</style>
