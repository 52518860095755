// 通用的时间范围禁用函数
export const isTimeDisabled = (time) => {
  const showTimeStamp = Math.floor(time.getTime() / 1000);
  const nowTimestamp = Math.floor(Date.now() / 1000);
  const twoYearsInSeconds = 2 * 365 * 24 * 60 * 60; // 两年的秒数
  // const maxRangeInSeconds = 30 * 24 * 60 * 60 * 6; // 半年的秒数
  // 检查是否在两年范围内
  if (
    showTimeStamp < nowTimestamp - twoYearsInSeconds ||
    showTimeStamp > nowTimestamp + twoYearsInSeconds
  ) {
    return true;
  }
  // 检查选择的时间是否超过了当前的时间
  if (showTimeStamp > nowTimestamp) {
    return true;
  }
  // 检查是否超出指定的时间跨度（半年）
  // if (startTime) {
  //   const startTimestamp = Math.floor(startTime);
  //   if (Math.abs(showTimeStamp - startTimestamp) > maxRangeInSeconds) {
  //     return true;
  //   }
  // }
  return false;
};
export const formatTimestamp = (timestamp, type = null) => {
  // 将十位秒级时间戳转换为毫秒级时间戳
  const date = new Date(timestamp * 1000); // 乘以1000转换为毫秒

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始，需加1
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  // const minute = String(date.getMinutes()).padStart(2, "0");
  // const second = String(date.getSeconds()).padStart(2, "0");
  if (type && hour == 23) {
    return `${year}-${month}-${day} ${hour}:59:59`;
  } else {
    return `${year}-${month}-${day} ${hour}:00:00`;
  }
};
export const formatDaystamp = (timestamp) => {
  // 将十位秒级时间戳转换为毫秒级时间戳
  const date = new Date(timestamp * 1000); // 乘以1000转换为毫秒

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始，需加1
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day} 23:59:59`;
};

/**
 * 找到符合时间跨度的起始时间和索引
 * @param {Array} data - 数据数组，包含时间字段 `time`
 * @param {Number} timeSpanInSeconds - 时间跨度（秒）
 * @returns {Object} - 包含实际的开始时间、数组索引及截取后的数组
 */
export function filterDataByTimeSpan(data, timeSpanInSeconds) {
  if (!data || !data.length) return null;
  // 工具函数：将时间字符串转为时间戳（秒级）
  const parseTime = (timeStr) => {
    return Math.floor(new Date(timeStr.replace(" ", "T")).getTime() / 1000);
  };

  // 获取结束时间（数组第一个值）和开始时间（数组最后一个值）的时间戳
  const endTimeStamp = parseTime(data[0].time); // 结束时间
  const startTimeStamp = parseTime(data[data.length - 1].time); // 开始时间

  // 检查时间跨度是否超出范围
  if (endTimeStamp - startTimeStamp <= timeSpanInSeconds) {
    return null;
  }
  // 遍历数组，从结束时间向前找符合时间跨度的实际开始时间
  for (let i = 0; i < data.length; i++) {
    const currentTimestamp = parseTime(data[i].time);
    if (endTimeStamp - currentTimestamp > timeSpanInSeconds) {
      return {
        // actualStartTime: data[i].time,
        actualStartIndex: i,
        // filteredData: data.slice(0, i + 1), // 截取符合时间跨度的数组
      };
    }
  }

  // 如果遍历完成仍无符合范围的时间，返回空结果
  return null;
}
