<template>
  <div>
    <DiffPage />

    <div class="contact">
      <div>以上价格数据仅供参考！</div>

      <div>业务联系: <br />微信：fhgj000088 <br />电话：18928449041</div>
    </div>
    <div class="m-t-beian">
      <a
        href="http://beian.miit.gov.cn"
        target="_blank"
        rel="nofollow noopener noreferrer"
        >粤ICP备2021048951号</a
      >
    </div>
  </div>
</template>

<script>
import DiffPage from "./components/DiffPage.vue";

export default {
  name: "App",
  components: {
    DiffPage,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 15px;
  font-size: 18px;
}
.contact {
  margin: 20px;
  text-align: left;
}
body::-webkit-scrollbar {
  display: none;
}
body {
  width: 100%!important;
}
</style>
