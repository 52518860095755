import { post } from "@/axios";

// 判断用户是否登录及是否为会员
export const getUserInfoAPI = ()=>post('/index.php?s=/login/getUserInfo')

//用户登录
export const loginAPI = (params) => post("/index.php?s=/login/login_xz_app", params);

// 用户注册
export const registerAPI = (params) => post("/index.php?s=/login/registerByPhoneV2", params);

// 修改用户密码
export const setUserPasswordAPI = (params) => post("/index.php/user/setUserPassword", params);

// 修改用户信息
export const setUserInfoAPI = (params)=>post('/index.php?s=/user/setUserInfo', params)

// 退出登录
export const logoutAPI = ()=>post('/index.php?s=/login/logout')

// 获取客服微信二维码
export const getWxCodeAPI = ()=>post('/index.php/user/getWxCode')

// 更改用户基差数值
export const setUserBasisAPI = (params)=>post('/index.php/user/setUserBasis', params)

// 获取基差数值
export const getUserBasisAPI = (params)=>post('/index.php/user/getUserBasis', params)

// 实时/分K基差列表
export const getCurrentMinBasisLineAPI = (params)=>post('/index.php/index/getCurrentMinBasisLine', params)

// 时K/日K基差列表
export const getHourDateBasisLineAPI = (params)=>post('/index.php/user/getHourDateBasisLine', params)

// 获取用户所有基差数值设置
export const getUserAllBasisAPI = ()=>post('/index.php/user/getUserAllBasis')

// 获取用户通知联系方式
export const getUserInformMessageAPI = ()=>post('/index.php/user/getUserInformMessage')