import { reactive } from "vue";
const rules = reactive({
  account: [
    { required: true, message: "请输入账号", trigger: "blur" },
    {
      required: true,
      message: "只能输入数字、字母",
      pattern: /^[A-Za-z0-9]+$/,
      trigger: "blur",
    },
  ],
  pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
  phone: [
    { required: true, message: "请输入手机号码", trigger: "blur" },
    {
      required: true,
      pattern: /^1[3-9]\d{9}$/,
      message: "请输入正确的手机号码",
      trigger: "blur",
    },
  ],
  email: [
    { required: true, message: "请输入邮箱", trigger: "blur" },
  ]
});

const registerConfig = {
  btnText: "注册并登录",
  labelWidth: "120px",
  dialogWidth: "500px",
  extends: "custom-btn",
  header: {
    newTitle: "注册账号",
  },
  formItems: [
    {
      type: "input",
      prop: "account",
      label: "账号：",
      placeholder: "请输入账号",
      initialValue: ""
    },
    {
      type: "input",
      prop: "pwd",
      label: "密码：",
      placeholder: "请输入密码",
      initialValue: "",
      inputType: 'password'
    },
    {
      type: "input",
      prop: "phone",
      label: "手机号码：",
      placeholder: "请输入您的手机号码",
      initialValue: ""
    },
    {
      type: "input",
      prop: "email",
      label: "邮箱：",
      placeholder: "用于接受基差邮件推送",
      initialValue: ""
    },
  ],
  rules: rules,
};

export default registerConfig;
