import { reactive } from "vue";
const rules = reactive({
    // 这里限制数字和字母
    account: [
      { required: true, message: '请输入账号', trigger: 'blur' },
      {
        required: true,
        message: "只能输入数字、字母",
        pattern: /^[A-Za-z0-9]+$/,
        trigger: "blur",
      },
    ],
    password: [
      { required: true, message: '请输入密码', trigger: 'blur' },
    ]
  });

const loginConfig = {
    btnText: '登录账号',
    header: {
      newTitle: '登录账号',
    },
    extends: 'custom-btn',
    formItems: [
      {
        type: 'input',
        prop: 'account',
        label: '账号：',
        placeholder: '请输入账号',
        initialValue: ''
      },
      {
        type: 'input',
        prop: 'password',
        label: '密码：',
        placeholder: '请输入密码',
        initialValue: '',
        inputType: 'password'
      },
    ],
  rules: rules,
};

export default loginConfig;
