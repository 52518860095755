import { reactive } from "vue";
import store from '@/store'
const rules = reactive({
  phone: [
    { required: true, message: "请输入手机号", trigger: "blur" },
    {
      required: true,
      pattern: /^1[3-9]\d{9}$/,
      message: '请输入正确的手机号码',
      trigger: 'blur',
    }
  ],
  email:[
    { required: true, message: "请输入邮箱", trigger: "blur" },
  ]
});

const changePhone = {
  btnText: "保存修改",
  labelWidth: '120px',
  dialogWidth: '500px',
  header: {
    newTitle: "修改手机号/邮箱",
  },
  formItems: [
    {
      type: "input",
      prop: "phone",
      label: "手机号码：",
      placeholder: "请输入手机号码",
      initialValue: store.state.userInfo.phone || ''
    },
    {
      type: "input",
      prop: "email",
      label: "邮箱：",
      placeholder: "用于接受基差邮件推送",
      initialValue: store.state.userInfo.email || ''
    }
  ],
  rules: rules,
};

export default changePhone;
