import { createStore } from "vuex";

// 创建一个新的 store 实例
const store = createStore({
  state() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {
        account: "",
        email: "",
        id: "",
        is_member: "",
        phone: "",
      },
    };
  },
  mutations: {
    updateUserInfo(state, data) {
      state.userInfo = data;
      localStorage.setItem("userInfo", JSON.stringify(data));
    },
    removeUserInfo(state) {
      state.userInfo = {
        account: "",
        email: "",
        id: "",
        is_member: "",
        phone: "",
      };
      localStorage.removeItem("userInfo");
    },
  },
});
export default store;
