import { reactive, ref } from "vue";
const passwordValue = ref('')
const rules = reactive({
  oldPassword: [{ required: true, message: "请输入原密码", trigger: "blur" }],
  password: [{ required: true, message: "请输入新密码", trigger: "blur" },{
    validator: (rule, value, callback) => {
      passwordValue.value = value
      callback();
    },
  }],
  password2: [
    { required: true, message: "请再次输入密码", trigger: "blur" },
    {
      validator: (rule, value, callback) => {
        if (!value) {
          callback(new Error("请再次输入密码"));
        } else if (value !== passwordValue.value) {
          callback(new Error("两次输入的密码不一致"));
        } else {
          callback();
        }
      },
      trigger: "blur",
    },
  ],
});
const changeConfig = {
  btnText: "修改密码",
  labelWidth: '100px',
  dialogWidth: '500px',
  header: {
    newTitle: "修改密码",
  },
  formItems: [
    {
      type: "input",
      prop: "oldPassword",
      label: "原密码：",
      placeholder: "请输入原密码",
      initialValue: "",
      inputType: 'password'
    },
    {
      type: "input",
      prop: "password",
      label: "新密码：",
      placeholder: "请输入新密码",
      initialValue: "",
      inputType: 'password'
    },
    {
      type: "input",
      prop: "password2",
      label: "新密码：",
      placeholder: "请再次输入密码",
      initialValue: "",
      inputType: 'password'
    },
  ],
  rules: rules,
};
export default changeConfig;
