const data = [
  {
    "ID": "g001",
    "Name": "水贝价VS国际价",
    "LeftLable": "水贝买价",
    "RightLable": "国际卖价",
    "ValueLable": "基差",
    "DiffConfList": [
      {
        "ID": "1",
        "Left": {
          "SrcChanle": "NASDAQ",
          "SrcName": "JZJ_au",
          "Tag": "Ask"
        },
        "Right": {
          "SrcChanle": "NASDAQ",
          "SrcName": "CFDXAU",
          "Tag": "Bid"
        },
        "Name": "黄金",
        "Decimal": 2
      },
      {
        "ID": "2",
        "Left": {
          "SrcChanle": "NASDAQ",
          "SrcName": "JZJ_ag_big",
          "Tag": "Ask"
        },
        "Right": {
          "SrcChanle": "NASDAQ",
          "SrcName": "CFDXAG_big",
          "Tag": "Bid"
        },
        "Name": "白银"
      },
      {
        "ID": "3",
        "Left": {
          "SrcChanle": "NASDAQ",
          "SrcName": "JZJ_pd",
          "Tag": "Ask"
        },
        "Right": {
          "SrcChanle": "NASDAQ",
          "SrcName": "SG5123",
          "Tag": "Bid"
        },
        "Name": "钯金",
        "Decimal": 2
      },

      {
        "ID": "4",
        "Left": {
          "SrcChanle": "NASDAQ",
          "SrcName": "JZJ_pt",
          "Tag": "Ask"
        },
        "Right": {
          "SrcChanle": "NASDAQ",
          "SrcName": "XPTUSD",
          "Tag": "Bid"
        },
        "Name": "铂金",
        "Decimal": 2
      }
    ]
  },
  {
    "ID": "g002",
    "Name": "水贝价VS上金",
    "LeftLable": "水贝买价",
    "RightLable": "上金价",
    "ValueLable": "基差",
    "DiffConfList": [
      {
        "ID": "1",
        "Left": {
          "SrcChanle": "NASDAQ",
          "SrcName": "JZJ_au",
          "Tag": "Ask"
        },
        "Right": {
          "SrcChanle": "NASDAQ",
          "SrcName": "SG5106",
          "Tag": "Bid"
        },
        "Name": "黄金",
        "Decimal": 2
      },
      {
        "ID": "2",
        "Left": {
          "SrcChanle": "NASDAQ",
          "SrcName": "JZJ_ag_big",
          "Tag": "Ask"
        },
        "Right": {
          "SrcChanle": "NASDAQ",
          "SrcName": "SG5108",
          "Tag": "Bid"
        },
        "Name": "白银"
      }
    ]
  },
  {
    "ID": "g003",
    "Name": "水贝价VS美期",
    "LeftLable": "水贝买价",
    "RightLable": "美期价",
    "ValueLable": "基差",
    "DiffConfList": [
      {
        "ID": "1",
        "Left": {
          "SrcChanle": "NASDAQ",
          "SrcName": "JZJ_au",
          "Tag": "Ask"
        },
        "Right": {
          "SrcChanle": "NASDAQ",
          "SrcName": "GC",
          "Tag": "Bid"
        },
        "Name": "黄金",
        "Decimal": 2
      },
      {
        "ID": "2",
        "Left": {
          "SrcChanle": "NASDAQ",
          "SrcName": "JZJ_ag_big",
          "Tag": "Ask"
        },
        "Right": {
          "SrcChanle": "NASDAQ",
          "SrcName": "SI_big",
          "Tag": "Bid"
        },
        "Name": "白银"
      },
      {
        "ID": "3",
        "Left": {
          "SrcChanle": "NASDAQ",
          "SrcName": "JZJ_pd",
          "Tag": "Ask"
        },
        "Right": {
          "SrcChanle": "NASDAQ",
          "SrcName": "PA",
          "Tag": "Bid"
        },
        "Name": "钯金",
        "Decimal": 3
      },
      {
        "ID": "4",
        "Left": {
          "SrcChanle": "NASDAQ",
          "SrcName": "JZJ_pt",
          "Tag": "Ask"
        },
        "Right": {
          "SrcChanle": "NASDAQ",
          "SrcName": "PL",
          "Tag": "Bid"
        },
        "Name": "铂金",
        "Decimal": 3
      }
    ]
  },
  {
    "ID": "g004",
    "Name": "水贝价VS上期",
    "LeftLable": "水贝买价",
    "RightLable": "上期价",
    "ValueLable": "基差",
    "DiffConfList": [
      {
        "ID": "1",
        "Left": {
          "SrcChanle": "NASDAQ",
          "SrcName": "JZJ_au",
          "Tag": "Ask"
        },
        "Right": {
          "SrcChanle": "NASDAQ",
          "SrcName": "aum",
          "Tag": "Ask"
        },
        "Name": "黄金",
        "Decimal": 2
      },
      {
        "ID": "2",
        "Left": {
          "SrcChanle": "NASDAQ",
          "SrcName": "JZJ_ag_big",
          "Tag": "Ask"
        },
        "Right": {
          "SrcChanle": "NASDAQ",
          "SrcName": "agm",
          "Tag": "Ask"
        },
        "Name": "白银"
      }
    ]
  },
  {
    "ID": "g005",
    "Name": "水贝价VS日金价",
    "LeftLable": "水贝买价",
    "RightLable": "日金价",
    "ValueLable": "基差",
    "DiffConfList": [
      {
        "ID": "1",
        "Left": {
          "SrcChanle": "NASDAQ",
          "SrcName": "JZJ_au",
          "Tag": "Ask"
        },
        "Right": {
          "SrcChanle": "NASDAQ",
          "SrcName": "jpau",
          "Tag": "Ask"
        },
        "Name": "黄金",
        "Decimal": 2
      }
    ]
  }
]
export default data